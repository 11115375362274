// import il8n from '@/assets/language/index.js';
// const { t } = il8n.global;
import { downBlobFile } from '@/utils/util'
import { deriveUrl } from '@/utils/pageData'

export default function (str) { 
    const downBlobFileFun = (parm,page,)=>{
        downBlobFile(deriveUrl[str][1],deriveUrl[str][0],{
            current: page.currentPage,
            size: page.pageSize,
            ...parm
        })
    }
    return {// 暴露出去
        downBlobFileFun
    }
}
