const walletSettingsFrom = [
    ['6:00','18:00','18:00','6:00'],
    ['6:00','19:00','19:00','6:00'],
    ['6:00','20:00','20:00','6:00'],
    ['6:00','21:00','21:00','6:00'],
    ['6:00','22:00','22:00','6:00'],
    
    ['7:00','18:00','18:00','7:00'],
    ['7:00','19:00','19:00','7:00'],
    ['7:00','20:00','20:00','7:00'],
    ['7:00','21:00','21:00','7:00'],
    ['7:00','22:00','22:00','7:00'],

    ['8:00','18:00','18:00','8:00'],
    ['8:00','19:00','19:00','8:00'],
    ['8:00','20:00','20:00','8:00'],
    ['8:00','21:00','21:00','8:00'],
    ['8:00','22:00','22:00','8:00'],

    ['9:00','18:00','18:00','9:00'],
    ['9:00','19:00','19:00','9:00'],
    ['9:00','20:00','20:00','9:00'],
    ['9:00','21:00','21:00','9:00'],
    ['8:00','22:00','22:00','9:00'],
]
const deriveUrl = {
    'topUp':['商户充值记录','/assets/assetsdepositrecord/exportByMer'],
    'withdrawCash':['商户提现记录','/assets/withdrawapply/exportByMer'],
    'aggregation':['商户归集记录','/assets/colletionrecord/exportByMer'],
    'walletWater':['商户钱包流水','/assets/walletchangelog/exportByMer'],
    'accountWater':['商户账户流水','/assets/accountchangelog/exportByMer'],
    'balanceWallet':['商户钱包余额','/assets/assetswalletinfo/exportByMer'],
    'balanceAccount':['商户账户余额','/assets/assetsaccountinfo/exportByMer'],
    'accountTime':['商户账户冻结','/assets/walletfrozen/exportByMer'],

    'topUpNft':['商户NFT充值记录','/assets/assetsdepositrecord/exportByMerNFT'],
    'withdrawCashNft':['商户NFT提现记录','/assets/withdrawapply/exportByMerNFT'],
    'aggregationNft':['商户NFT归集记录','/assets/colletionrecord/exportByUserNFT'],
    'walletWaterNft':['商户NFT钱包流水','/assets/walletchangelog/exportByMerNFT'],
    'accountWaterNft':['商户NFT账户流水','/assets/accountchangelog/exportByMerNFT'],
    'balanceWalletNft':['商户NFT钱包余额','/assets/assetswalletinfo/exportByMerNFT'],
    'balanceAccountNft':['商户NFT账户余额','/assets/assetsaccountinfo/exportByMerNFT'],
    'nftSeries':['NFT系列记录','/assets/nftdeployinfo/exportByMer'],
    'nftWorks':['NFT作品记录','/assets/nftmintinfo/exportByMer'],

    
    'topUp_':['客户充值记录','/assets/assetsdepositrecord/exportByUser'],
    'withdrawCash_':['客户提现记录','/assets/withdrawapply/exportByUser'],
    'aggregation_':['客户归集记录','/assets/colletionrecord/exportByUser'],
    'walletWater_':['客户钱包流水','/assets/walletchangelog/exportByUser'],
    'accountWater_':['客户账户流水','/assets/accountchangelog/exportByUser'],
    'balanceWallet_':['客户钱包余额','/assets/assetswalletinfo/exportByUser'],
    'balanceAccount_':['客户账户余额','/assets/assetsaccountinfo/exportByUser'],
    'address_':['客户地址','/assets/assetswalletinfo/exportByUser'],

    'topUpNft_':['客户NFT充值记录','/assets/assetsdepositrecord/exportByUserNFT'],
    'withdrawCashNft_':['客户NFT提现记录','/assets/withdrawapply/exportByUserNFT'],
    'walletWaterNft_':['客户NFT钱包流水','/assets/walletchangelog/exportByUserNFT'],
    'accountWaterNft_':['客户NFT账户流水','/assets/accountchangelog/exportByUserNFT'],
    'balanceWalletNft_':['客户NFT钱包余额','/assets/assetswalletinfo/exportByUserNFT'],
    'balanceAccountNft_':['客户NFT账户余额','/assets/assetsaccountinfo/exportByUserNFT'],
    'addressNft_':['客户NFT地址','/assets/assetswalletinfo/exportByUserNFT'],
}
export {
    walletSettingsFrom,
    deriveUrl
}